const postForm = () => {
    const feedbackForm = document.querySelector('.js-feedback-form');

    if (feedbackForm) {
        const endpoint = '/contact-request';
        const allInputs = [...feedbackForm.querySelectorAll('.form__input')];

        const setInputErrorClass = input => input.classList.add('not-valid');
        const removeErrorsClass = item => item.classList.remove('not-valid');
        const setFormStatusLoading = () => feedbackForm.classList.add('form--loading');
        const removeFormStatusLoading = () => feedbackForm.classList.remove('form--loading');
        const setFormStatusLoaded = () => feedbackForm.classList.add('form--loaded');
        const resetAllInputs = () => {
            allInputs.forEach(input => {
                removeErrorsClass(input);
                if (input.nextElementSibling.className === 'form__error') {
                    input.nextElementSibling.remove()
                }
            });
        };
        const resetForm = () => {
            setTimeout(() => {
                feedbackForm.reset();
                feedbackForm.className = 'form contacts__form js-feedback-form';
            }, 5000)
        };
        const createErrorItem = (closestInput, content) => {
            closestInput.insertAdjacentHTML('afterend', `
                <div class='form__error'>
                    ${content}
                </div>
            `);
        };

        feedbackForm.addEventListener('submit', (e) => {
            e.preventDefault();
            resetAllInputs();
            setFormStatusLoading();

            fetch(endpoint, {
                method: 'POST',
                body: new FormData(e.target),
            })
                .then(res => {
                    if (res.ok) {
                        setFormStatusLoaded();
                        resetForm();
                    }
                    return res.json()
                })
                .then(data => {
                    removeFormStatusLoading();

                    if (data.errorFields) {
                        data.errorFields.forEach(key => {
                            switch (key.path) {
                                case 'email':
                                    setInputErrorClass(allInputs[0]);
                                    createErrorItem(allInputs[0], key.messages);
                                    break
                                case 'name':
                                    setInputErrorClass(allInputs[1]);
                                    createErrorItem(allInputs[1], key.messages);
                                    break
                                case 'message':
                                    setInputErrorClass(allInputs[2]);
                                    createErrorItem(allInputs[2], key.messages);
                                    break
                                default:
                            }
                        })
                    }
                })
        })
    }
}

export default postForm;
