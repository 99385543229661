const scrollToTop = () => {
  const scrollToTopBtn = document.querySelector('.js-link-top')

  if (scrollToTopBtn) {
    scrollToTopBtn.addEventListener('click', () => {
      window.scrollTo({top: 0, behavior: 'smooth'})
    })
  }
}

export default scrollToTop;
