const header = () => {
    const siteHeader = document.querySelector('.js-header');

    if (siteHeader) {
        const addTransparent = () => siteHeader.classList.add('header--hero');
        const removeTransparent = () => siteHeader.classList.remove('header--hero');
        let scrollPos = 0;

        document.addEventListener("scroll", () => {

            if (window.pageYOffset > 50) {
                removeTransparent();
            } else {
                addTransparent();
            }

            scrollPos = document.body.getBoundingClientRect().top;
        });
    }
}

export default header
