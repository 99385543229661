import menu from "./components/menu";
import scrollToTop from "./components/scrollToTop";
import header from "./components/header";
import postForm from "./components/postForm";
import slider from "./components/slider";
// import linkLimiter from "./components/linkLimiter";

menu();
scrollToTop();
header();
postForm();
slider();
// linkLimiter();
